import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [{
  path: "/",
  redirect: "index"
},
{
  path: "/index",
  name: "index",
  component: () => import("../views/IndexView.vue"),
  meta: {
    title: "首页",
  },
},
{
  path: "/search",
  name: "search",
  component: () => import("../views/SearchView.vue"),
  meta: {
    title: "案件查询",
  },
},
{
  path: "/success",
  name: "success",
  component: () => import("../views/SuccessView.vue"),
  meta: {
    title: "成功",
  },
},
{
  path: "/error",
  name: "error",
  component: () => import("../views/ErrorView.vue"),
  meta: {
    title: "失败",
  },
},
]

const router = new VueRouter({
  routes
})

export default router