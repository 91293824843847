<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
    
  },
  methods: {

  },

}
</script>

<style>
@import url(../src/style/global.css);
</style>